import "./Group.css"
import types from "../ItemTypes"

export const Group = ({title, items, type, groupedBy}) => {
    const Component = types[type]
    return (<section className="group">
        <h3 className="group__title">{title}</h3>
        <ul className="group__items">
            {items.map((item, index) => <li key={index}><Component item={item} groupedBy={groupedBy} /></li>)}
        </ul>
    </section>)
}