import "./Typography.css";

export const Typography = ({item, groupedBy}) => {
    const nameClass = item.name.toLowerCase().replace(' ', '-');

    return (<div className={`typo ${nameClass}`}>
        <h3 className="typo__name">{groupedBy === "name" ? item.screen : item.name}</h3>
        <div
            className="typo__example"
            style={{
                fontFamily: item.fontFamily,
                fontSize: item.fontSize,
                fontWeight: item.fontWeight,
                lineHeight: item.lineHeight
            }}
        >
            Lorem ipsum dolor sit amet
        </div>
        <dl className="typo__info">
            <dt>lettertype:</dt><dd>{item.fontFamily}</dd>
            <dt>grootte:</dt><dd>{item.fontSize}</dd>
            <dt>gewicht:</dt><dd>{item.fontWeight}</dd>
            <dt>lijnhoogte:</dt><dd>{item.lineHeight}</dd>
        </dl>
    </div>);
}