import "./Switch.css"

export const Switch = ({label, options, checked, changeGrouping, identifier}) => {
    return (
        <fieldset className="switch">
            <legend>{label}</legend>
            <div className="switch__options">
                {Object.keys(options).map(option => (
                    <div className="switch__option" key={option}>
                        <input
                            className="switch__input"
                            type="radio" name={`${identifier}-grouping`}
                            id={option}
                            checked={option === checked}
                            onChange={(() => changeGrouping(option))}
                        />
                        <label htmlFor={option} className="switch__label">
                            {options[option]}
                        </label>
                    </div>
                ))}
            </div>
        </fieldset>
    )
}