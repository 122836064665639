import { useState } from "react";
import "./Color.css"

export const Color = ({item}) => {
    const [copied, setCopied] = useState(false);

    const copy = color => {
        window.navigator.clipboard.writeText(color.toUpperCase());
        setCopied(true);
        window.setTimeout(() => setCopied(false), 2500);
    }

    return (<div className="color">
        <div
            className={`color__swatch ${copied ? "color__swatch--copied" : ""}`}
            style={{"--color": item.value}}
            onClick={() => copy(item.value)}
        ></div>
        <h3 className="color__name">
            {item.name}
        </h3>
        <p className="color__hex">{item.value.toUpperCase()}</p>
    </div>);
}
