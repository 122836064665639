import { useEffect, useState } from 'react';
import './App.css';
import { Category } from './components/Category/Category';
import preval from 'preval.macro';

function App() {
    const [data, setData] = useState(null)
    const [types, setTypes] = useState(null)

    useEffect(() => {
        window.fetch("data.json")
            .then(data => data.json())
            .then(data => {
                setData(data)
                setTypes(Object.keys(data))
            })
    }, [])

    // Stelt een datum in die vast blijft staan op het moment van de build
    const dateTimeStamp = preval`module.exports = new Date().toLocaleString("nl-BE");`

  return (
    <div className="app">
        <h1>Klasse stijlgids</h1>
        <nav className="nav">
            <ul className="nav__list">
                {types ? types.map(type => <li key={type}><a href={`#${type}`}>{type}</a></li>) : ""}
            </ul>
        </nav>
        {data && types ? types.map((type, index) => <Category key={index} id={type} data={data[type]} type={type} />) : "loading..."}
        <footer>
            Laatst geüpdate op {dateTimeStamp}
        </footer>
    </div>
  );
}

export default App;
