import { useState } from "react"
import { Group } from "../Group/Group"
import { Switch } from "../Form/Switch/Switch"
import "./Category.css";

export const Category = ({ data, type }) => {
    const getGroups = (grouping = activeGrouping) => data.items.reduce((grouped, item) => {
        if (item[grouping]) {
            if (!grouped[item[grouping]]) {
                grouped[item[grouping]] = []
            }
            grouped[item[grouping]].push(item)
        }

        return grouped
    }, {})

    const changeGrouping = grouping => {
        setGrouping(grouping)
        setData(getGroups(grouping))

        window.scrollBy(0, document.getElementById(type).getBoundingClientRect().top - 20)
    }

    const possibleGroupings = data.groupings
    const groupingKeys = Object.keys(possibleGroupings)
    const defaultGrouping = groupingKeys[0]

    const [activeGrouping, setGrouping] = useState(defaultGrouping)
    const [groupedData, setData] = useState(getGroups(defaultGrouping))

    return (<section className="category" id={type}>
        <div className="category__title">
            <h2>{type}</h2>
            {groupingKeys.length > 1 ? (<Switch
                label="Groepeer op"
                options={possibleGroupings}
                checked={activeGrouping}
                changeGrouping={changeGrouping}
                identifier={type}
            />) : null}
        </div>
        {Object.keys(groupedData).map(group => (
            <Group
                key={group}
                items={groupedData[group]}
                title={group}
                type={type}
                groupedBy={activeGrouping}
            />
        ))}
    </section>)
}
